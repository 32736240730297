import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../layout"),
    redirect: "home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home"),
      },
      {
        path: "solution",
        name: "solution",
        component: () => import("../views/solution"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/about"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("../views/news"),
      },
      {
        path: "news/:id",
        name: "news_detail",
        component: () => import("../views/news/detail"),
      },
      {
        path: "joinus",
        name: "joinus",
        component: () => import("../views/joinus"),
      },

      { path: "*", redirect: "home" },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
