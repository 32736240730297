<template>
  <div id="app">
    <!-- <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition> -->
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url(./assets/common.css);
</style>
