<template>
  <div id="banner" :style="`background-image: url(${url});`">
    <h3 :style="`color:${color ? color : '#fff'}`">{{ title }}</h3>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    url: String,
    title: String,
    color: String,
    scale: { type: Number, default: () => 0.47 },
  },
  data() {
    return {
      $list: null,
    };
  },
  mounted() {
    this.calcHeight();
    window.addEventListener("resize", this.calcHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calcHeight);
  },
  methods: {
    calcHeight() {
      // 轮播图
      if (!this.$list) {
        this.$list = document.querySelector("#banner ");
      }
      this.$list.style.height = this.$list.clientWidth * this.scale + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/mixin.scss";
#banner {
  width: 100%;
  @include center();
  @include bg();
  flex-direction: column;
  position: relative;
  h3 {
    font-size: 42px;
    color: #fff;
  }
}
</style>
