import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/index.scss";
import "animate.css";
import TitleBar from "@/components/TitleBar";
import Banner from "@/components/Banner";
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player'

Vue.config.productionTip = false;

Vue.component("TitleBar", TitleBar);
Vue.component("Banner", Banner);
Vue.use(Element);
Vue.use(VideoPlayer);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
