<template>
  <div class="title-bar">
    <h3>{{ enTitle }}</h3>
    <h4>{{ title }}</h4>
    <div class="line-title"></div>
    <p>{{ desc }}</p>
  </div>
</template>
<script>
export default {
  props: { title: String, enTitle: String, desc: String },
};
</script>
<style lang="scss" scoped>
.title-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  h3 {
    margin: 0;
    color: #1CB0F1;
    font-size: 2.813vw;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 2.969vw;
  }
  h4 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }
  .line-title {
    position: relative;
    width: 350px;
    height: 1px;
    background: #00F6FF;
    margin: 16px auto;
    // &::after {
    //   position: absolute;
    //   content: "";
    //   top: -2px;
    //   left: 50%;
    //   margin-left: -25px;
    //   width: 50px;
    //   height: 5px;
    //   background: #90c31f;
    // }
  }
  p {
    margin: 0;
    color: #999999;
    font-size: 14px;
  }
}
</style>
